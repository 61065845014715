import Footer from "./footer";
import Navbar from "./navbar";

function Template(props) {
  return (
    <>
      <Navbar></Navbar>
      <div className="container-fluid">
        {props.children}
      </div>
      <Footer></Footer>
    </>
  );
}
export default Template;
