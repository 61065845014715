import Template from "../components/Template";

function Home() {
  return (
    <>
      <Template>
        
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae nostrum esse minima exercitationem iusto. Debitis placeat autem corporis alias soluta! Explicabo voluptate hic illo pariatur animi laudantium iusto, dolorem impedit.
       
      </Template>
    </>
  );
}
export default Home;
